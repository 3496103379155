import {createSlice} from '@reduxjs/toolkit'

const unasignedCasesSlice = createSlice({
    name:'unasigned',
    initialState:[],

    reducers: {
        set:(state,action)=>{
            return action.payload
        },
        unset:(state,action)=>{
            return []
        }

    }
})


export const unasignedReducer = unasignedCasesSlice.reducer
export const {set,unset} = unasignedCasesSlice.actions