import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Server_url } from '../../utils/roots'


const superUserApi = createApi({
    reducerPath: 'super_user_api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${Server_url}`,
        prepareHeaders: (headers,{getState})=>{
            const access = getState().user.access
            if(access)
                headers.set("Authorization", `JWT ${access}`)
            return headers
        }
    }),endpoints(builder){
        return{
            
            getMediators:builder.query({
                providesTags:['mediators'],
                query:()=>{
                    return{
                        url:'/users/mediator_view/',
                        method:'GET'
                    }
                }
            }),
            deleteMediator: builder.mutation({
                query:({userId})=>{
                    return{
                        url:`/users/mediator_view/${userId}/`,
                        method:'DELETE',
                    }
                }
            }),
            changing_userPassword: builder.mutation({
                query:({userId, password})=>{
                    return{
                        url:'/users/user_view/changing_password/',
                        method:'PUT',
                        body:{
                            id:userId,
                            password:password
                        }
                    }
                }
            }),
            changeFirstLogin: builder.mutation({
                query:({userId})=>{
                    return{
                        url:`/users/user_view/change_first_entry_attribute/`,
                        method:'PUT',
                        body:{
                            id:userId
                        }
                    }
                }
            }),
            getUserById: builder.query({
                query:({userId})=>{
                    return{
                        url:'users/user_view/get_user_by_id/',
                        method:'GET',
                        params:{
                            id:userId
                        }
                    }
                }
            }),
            getMediatorsWithCases: builder.query({
                query:({page})=>{
                    return{
                        url:`users/mediator_view/getCasesWithMediator/?index=${page}`,
                        method:'GET',
                    }
                }
            }),
            getUnasignedCases: builder.query({
                query:()=>{
                    return{
                        url:'session/case/get_unasign_cases/',
                        method:'GET',
                    }
                }
            }),
            assignMediatorTCase:builder.mutation({
                query:({caseId,mediatorId})=>{
                    return{
                        url:`session/case/${caseId}/`,
                        method:'PATCH',
                        body:{
                            mediator:mediatorId
                        }
                    }
                }

            }),
            removeGroupOwner: builder.mutation({
                query:({groupId,admin})=>{
                    return{
                        url: `agora/groups/remove_group_admin/?group_id=${groupId}/${admin}`,
                        method:'DELETE'
                    }
                }
            }),
            deleteOwner: builder.mutation({
                query:({owner})=>{
                    return{
                        url: `agora/users/delete_owner/?owner=${owner}`,
                        method:'DELETE',
                    }
                }
            }),
            set_owner_to_groups: builder.mutation({
                query:({groups,owner})=>{
                    if (!Array.isArray(groups)) {
                        console.error("Groups is not a list:", groups);
                        return;  // or handle the error appropriately
                    }
                

                    return{
                        url:'agora/groups/set_owner_to_case/',
                        body:{
                            groups:groups,
                            owner:owner,
                        },
                        method:'PUT'
                    }
                }
            }),
            joinToGroups: builder.mutation({
                query:({username, groups})=>{
                    return{
                        url:'agora/groups/joinToGroups/',
                        method:'POST', 
                        body:{
                            username:username,
                            groups:groups,
                        }
                    }
                }
            })
           
        }
       
        
    }})

    export {superUserApi}