import "../../styles/components/text_field.css"
import {useState} from 'react'
import {eyeIcon, eyeSlashIcon} from '../../utils/icons/bootstarp_icons'
const  TextField=({type,placeHolder,
                onChange,name,length,
                altitude,align,parentRef,
                value, required,isValid,
                warnText,inGrid,id,limitChars,minLength,maxLength,isPassword
            })=>{
    const [userReacted , setUserReacted ] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [focusInput, setFocusInput] = useState(false)
    
    
    let classPostfix = align === "left" ? "-la" : "" //la for left alignment
    const _valid = isValid===false? false : true
    const _warnText = warnText ? warnText : "Invalid " + name 
    
    const inputStyle = {
        width: length? length : "12em",
        height: altitude? altitude : "1em",
        margin: length === "100%" ? "0" : "1em",
        boxSizing:inGrid?"border-box" : "content-box",
    }
    /*This designated for a specific case 
    where we might want the field to 
    take over a cell and need 100% width to
    In this case margin may cause overflow*/

    const labelStyle ={
        transform:inGrid? "translateY(-100%)" : "",
        left:inGrid ? "0" : "7%",

    }

    const handleChange =(e)=>{
        setUserReacted(true)
        onChange(e)
    }
   

    return(
        <div >
        <section  style={{position:isPassword?'relative':'', width:'fit-content'}}  className="text-input">
            
            {!(userReacted)&&!(_valid)&&
            <label 
                htmlFor={name}
                className="text-input--lbl"
                style={labelStyle}
            >
                <span style={{display:'flex',justifyContent:'center',fontSize:'small'}}>{_warnText}</span>
                
            </label>}
            <div id={id || ''}></div>
            <input
                maxLength={limitChars || ''}
                max={maxLength || ''}
                min={minLength || ''}
                ref={parentRef}
                name={name}
                onChange={e=>handleChange(e)} 
                className={`text-input--in${classPostfix}`}
                type={showPassword?'text':type} 
                placeholder={placeHolder} 
                align="left"
                style={inputStyle}
                value={value}
                required={required}
                id={name}
                onFocus={()=>setFocusInput(true)}
                onBlur={()=>setFocusInput(false)}
                
        
            />
            {isPassword&&<div onClick={(e)=>{
                e.preventDefault()
                setShowPassword(prev=>!prev)

                
                }} style={{color:focusInput?'var(--green-dark-shaded':'white'}}  className="show-password-btn">{showPassword?eyeSlashIcon:eyeIcon}</div>}
        </section>
        </div>

    )}
export default TextField