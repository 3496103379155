import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Server_url } from "../../utils/roots";



const msgApi = createApi({
    reducerPath: 'msg',
    baseQuery: fetchBaseQuery({
        baseUrl: `${Server_url}`,
        prepareHeaders: (headers,{getState})=>{
            const access = getState().user.access
            if(access)
                headers.set("Authorization", `JWT ${access}`)
            return headers
        }
    }),
    endpoints(builder){
        return {
            postNewMessage: builder.mutation({
                query: ({date_time, time_left_last_message,text,group_chat,member,num_of_chars,user,caseId, sender_name})=>{
                    return{
                        url: '/session/message/',
                        method:'POST',
                        body:{
                            date_time:date_time,
                            time_left_last_message:time_left_last_message,
                            num_of_chars:num_of_chars,
                            text:text,
                            group_chat:group_chat,
                            member:member,
                            case:caseId,
                            user:user,
                            sender_name:sender_name,
                        }

                    }
                    
                }
            }),
            getMessageByCase: builder.query({
                query: ({caseId, pageIndex})=>{
                    return{
                        url: `/session/message/get_message_by_case/?case=${caseId}&page=${pageIndex}`,
                        method:'GET'
                    }
                }
            })
        }
    }
})

export {msgApi}