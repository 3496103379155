import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Server_url } from '../../utils/roots'


const cachesApi = createApi({
    reducerPath: 'cachesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${Server_url}`,
        prepareHeaders: (headers,{getState})=>{
            const access = getState().user.access
            if(access)
                headers.set("Authorization", `JWT ${access}`)
            return headers
        }
    }),
    endpoints(builder){
        return {
            getOnlineList: builder.query({
                query: ({chatId})=>{
                    return {
                        url: `/chat/chat_group/${chatId}/list_members/`,
                        method: 'GET',
                    }
                }
            }),
            addOnlineUser: builder.mutation({
                query: ({chatId, userId}) =>{
                    return {
                        url: `/chat/chat_group/${chatId}/add_member/${userId}/`,
                        method: 'POST',
                    }
                }
            }),
            removeOnlineUser: builder.mutation({
                query: ({chatId, userId})=>{
                    return {
                        url: `/chat/chat_group/${chatId}/remove_member/${userId}/`,
                        method: 'DELETE'
                    }
                }
            }),
            removeChatFromCache: builder.mutation({
                query: ({chatId})=>{
                    return {
                        url: `/chat/chat_group/${chatId}/`
                    }

                }
            }),
        }
    }
})
export {cachesApi}