import { useEffect, useState } from "react"



const useWindowSize = ()=>{
    const [heigth, setHeigth] = useState(window.innerHeight)
    const [width, setWidth] = useState(window.innerWidth)


    useEffect(()=>{
        window.addEventListener('resize',handleResize)

        return ()=>window.removeEventListener('resize', handleResize)

    },[])

    const handleResize = ()=>{
        setHeigth(()=>window.innerHeight)
        setWidth(()=>window.innerWidth)
    }


    return {heigth, width}
}

export default useWindowSize