import { createSlice } from "@reduxjs/toolkit";


const memberSlice = createSlice({
    name:'member',
    initialState:{
        memberSide:'',
        memberId:'',
        allMembers:{}
        
    },
    reducers:{
        setMemberSide:(state,action)=>{
            const {side, id} = action.payload
            state.memberSide = side
            state.memberId = id
        },
        setAllMembers: (state,action) =>{
            state.allMembers = action.payload
        },
    }
})

export const memberReducer = memberSlice.reducer
export const {setMemberSide, setAllMembers} = memberSlice.actions