import { createSlice } from "@reduxjs/toolkit";


const noftificationSlice = createSlice({
    name:'notification',
    initialState:{
        onlineStatus:false,
        messageNotification: null,
        newTextMessage:null,
        groupNotification:null,
        shuttle:null,
        typing:null,
        online:null,
        chatStart:null
    },

    reducers:{
        setNewNotification:(state, action)=>{
            const {key, value} = action.payload
            state[key] = value
        },
        
        setOnlineStatus:(state,action)=>{
            state.onlineStatus = action.payload
        },

        setNewMessageNotification:(state,action)=>{
            state.messageNotification = action.payload
        },
        setNewTextMessage:(state,action) =>{
            state.newTextMessage = action.payload

        },

        resetNewTextMessage:(state)=>{
            state.newTextMessage = null

        },
        setNewGroupNotification:(state,action)=>{
            state.groupNotification = action.payload
        },

        resetNotifications:(state)=>{
            state.onlineStatus = false
            state.messageNotification = null
            state.newTextMessage = null
            state.groupNotification = null
            state.typing = null
            state.online = null
            state.chatStart = null
        }
    }
})


export const notificationReducer = noftificationSlice.reducer
export const {setNewMessageNotification, setOnlineStatus, resetNotifications, resetNewTextMessage, setNewTextMessage, setNewGroupNotification, setNewNotification } = noftificationSlice.actions