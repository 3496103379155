import { createSlice } from "@reduxjs/toolkit";


const onlineUsersSlice = createSlice({
    name:'onlineUsers',
    initialState:[]
    ,
    reducers:{
        setAllOnlineUsers:(state, action)=>{
            return action.payload
        },
        clearOnlineUsers:()=>{
            return []
        },
        updateOnlineUsers:(state,action)=>{
            const {userId,status } = action.payload 
  
            
            if(status === 'offline'){
                return state.filter(user=>user !== userId)
            }

            else if(status === 'online'){
                if(!state.includes(userId))
                    return [...state, userId]
            }
        }
    }
})


export const onlineUsersReducer = onlineUsersSlice.reducer
export const {setAllOnlineUsers, clearOnlineUsers, updateOnlineUsers} = onlineUsersSlice.actions