import { createSlice } from "@reduxjs/toolkit";


const privateMsg = createSlice({
    name:'privateMsg',
    initialState:{
        from:'',
        msg:'',
    },
    reducers:{
        postPrivateMsg: (state, action)=>{
            const {from , msg } = action.payload
            return {from:from, msg:msg}
        },
        clearPrivateMsg:()=>{
            return {from:'', msg:''}
        }
    }
})

export const privateMsgReducer = privateMsg.reducer
export const { postPrivateMsg, clearPrivateMsg} = privateMsg.actions